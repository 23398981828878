import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomTable  from '../../components/tables/customTable'
import Pusher from "pusher-js";
import { useDocumentTitle } from '../../components/useDocumentTitle';

import {
    debouncedGetJobs,
    debouncedDeleteJob,
    debouncedGetChooseJob,
    debouncedGetVehicleMakes,
    debouncedGetColorList,
    resetOpenJob,
    setIsNewJob
} from '../../store/job/actions'
import { dateFormat } from '../../helpers/stringFormat'
import ExpandedCard from '../../components/modals/ExpandedCard';

const JobList = (props) => {
    const history = useHistory();
    const location = useLocation();

    useDocumentTitle("Helios - Job List")
    const [archived, setArchived] = useState(JSON.parse(localStorage.getItem('archivedJobs')) ?? false)
    const [selectedJob, setSelectedJob] = useState({});
    const [pulseAnimation, setPulseAnimation] = useState(false)
    const [archivedJob, setArchivedJob] = useState(false);
    const params = new URLSearchParams(location.search);
    const jobId = params.get('jobId');

    const columns = [
        {
            title: 'RO Number',
            fixed: 'left',
            align: 'left',
            sorter: (a, b) => (a.ro_number || "").localeCompare(b.ro_number || ""),
            render: record => <span>{record.ro_number}</span>
        },
        {
            title: 'Customer',
            align: 'left',
            sorter: (a, b) => (a.customer.firstname || "").localeCompare(b.customer.firstname || ""),
            render: record => (
                <a className="table_anchor">
                    {record.customer && record.customer.firstname + ' ' + record.customer.lastname}
                </a>
            ),
        },
        {
            title: 'Vehicle',
            align: 'left',
            render: record => (
                <span>
                    {record.vehicle &&
                        `${record.vehicle?.year} ${record.vehicle?.make?.name} ${record.vehicle.model} ${record.vehicle?.vehicle_color ? record.vehicle?.vehicle_color : ''}`
                    }
                </span>
            ),
        },
        {
            title: 'Status',
            align: 'left',
            sorter: (a, b) => (a.plan_date || "").localeCompare(b.plan_date || ""),
            render: record => (
                <span>
                    {record.status_from_organization == null ? 
                        (record.plan_date == null ? 'Unscheduled' : 'Scheduled') 
                        : record.status_from_organization.name}
                </span>
            ),
        },
        {
            title: 'Assigned To',
            align: 'left',
            sorter: (a, b) => (a.assigned_to_user && a.assigned_to_user.firstname || "").localeCompare(b.assigned_to_user && b.assigned_to_user.firstname || ""),
            render: record => (
                <span>
                    {record.assigned_to_user ? record.assigned_to_user.firstname : ''} {record.assigned_to_user ? record.assigned_to_user.lastname : ''}
                </span>
            ),
        },
        {
            title: 'Last updated',
            dataIndex: 'updated_at',
            align: 'left',
            sorter: (a, b) => (a.updated_at || "").localeCompare(b.updated_at || ""),
            render: date => dateFormat(date, 'TXT_YEAR_DATE')
        }
    ];

    useEffect(() => {
        setArchived(JSON.parse(localStorage.getItem('archivedJobs'))?? false)
        props.debouncedGetJobs({ archived: archived ?? false }, true)

        var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_API_KEY}`, {
            cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
        });

        var channel = pusher.subscribe("product-board");
        channel.bind("job-event", (data) => {
            if (data.event == 'card changed') {
                props.debouncedGetJobs({ archived: archived ?? false }, false)
            }
        });

        var orgChannel = pusher.subscribe("organizations");
        orgChannel.bind("choose-event", (data) => {
            props.debouncedGetJobs({ archived: archived ?? false }, true)
        });

        return (() => {
			pusher.unsubscribe('organizations')
			pusher.unsubscribe('product-board')
		})
    }, [])

    useEffect(() => {
        props.debouncedGetVehicleMakes()
        props.debouncedGetColorList()
    }, [])

    useEffect(() => {
        if (props?.jobs?.data?.length > 0 && jobId)
            handleJobCardClick()
    }, [props?.jobs?.data?.length, jobId])

    const handleToggle = (checked) => {
        localStorage.setItem('archivedJobs', JSON.stringify(checked))
        setArchived(checked)
    };

    const changeBackground = (color) => {
        setSelectedJob({ ...selectedJob, color_id: color?.id, job_color: color })
    }

    const onArchiveJob = (status) => {
        // status - 0: archive, 1: unarchive
        if (status == 0) {
            setArchivedJob(true)
        } else if (status == 1) {
            setPulseAnimation(false)
            setArchivedJob(false)
            setTimeout(() => {
                setPulseAnimation(false)
            }, 5000)
        }
    }

    const handleJobCardClick = async () => {
        let card = await document.querySelector('#job_' + jobId);
        if (card) {
            card.click();
        }
    };

    return (
        <div className="full-height-top">
            <CustomTable
                columns={columns}
                searchBy='customer'
                hideActionColumn={true}
                data={props?.jobs?.data?.sort((a, b) => a.customer.lastname.localeCompare(b.customer.lastname))}
                getData={(params) => props.debouncedGetJobs(params, true)}
                deleteRecord={(id) => props.debouncedDeleteJob(id)}
                route="/job"
                refreshTable={() => props.debouncedGetJobs({ archived: archived }, true)}
                loading={props.jobs.loading}
                setArchived={handleToggle}
                archived={archived}
                onOpenJob={(item) => {
                    props.debouncedGetChooseJob(item.id, true)
                    setSelectedJob(item)
                    const queryParams = '?jobId=' + item.id;
                    window.history.pushState({}, '', `#${history.location.pathname}${queryParams}`);
                }}
                setIsNewJob={props.setIsNewJob}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        jobs: state.JobReducer.jobs,
        newJob: state.JobReducer.newJob,
        colorList: state.JobReducer.colorList
    }
}

const mapDispachToProps = (dispatch) => ({
    debouncedGetJobs: (offset, loading) => debouncedGetJobs(dispatch, offset, loading),
    debouncedDeleteJob: (id) => debouncedDeleteJob(dispatch, id),
    debouncedGetChooseJob: (id, loading) => debouncedGetChooseJob(dispatch, id, loading),
    debouncedGetVehicleMakes: () => debouncedGetVehicleMakes(dispatch),
    debouncedGetColorList: () => debouncedGetColorList(dispatch),
    resetOpenJob: () => dispatch(resetOpenJob()),
    setIsNewJob: (isNew) =>  dispatch(setIsNewJob(isNew))

})

export default connect(mapStateToProps, mapDispachToProps)(JobList)