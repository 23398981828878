import {
    GET_VEHICLE_MAKES,
    CREATE_NEW_JOB,
    UPDATE_JOB,
    INITIAL_STATES,
    GET_JOBS,
    GET_CHOOSE_JOB,
    DEL_JOB,
    GET_CUSTOMERS,
    CREATE_CUSTOMER,
    EDIT_CUSTOMER,
    GET_JOB_STATUSES,
    GET_JOB_BY_ORG,
    GET_ASSIGNED_USERS,
    GET_JOB_COLORS,
    UPDATE_JOB_STATUS,
    UPLOAD_JOB_VEHICLE_IMG,
    GET_PLANNING_JOBS,
    GET_JOB_VEHICLE_IMG,
    GET_ASSIGNABLE_USERS,
    SCHEDULE_PLANNING_JOBS,
    SEARCHKEY,
    GET_JOB_FILTERS,
    RESET_OPEN_JOB,
    SET_MODAL,
    SHOW_EMPTY_JOBS_MODAL,
    HIDE_EMPTY_JOBS_MODAL,
    SET_IS_NEW_JOB
} from "./actionTypes"
import debounce from 'lodash.debounce';

export const getVehicleMakes = () => {
    return {
        type: GET_VEHICLE_MAKES
    }
}

export const debouncedGetVehicleMakes = debounce((dispatch) => {
    dispatch(getVehicleMakes());
}, 300);

export const getVehicleMakesSuccess = (payload) => {
    return {
        type: GET_VEHICLE_MAKES + `_SUCCESS`,
        payload: payload
    }
}

export const getFailed = (payload) => {
    return {
        type: GET_VEHICLE_MAKES + `_FAILED`,
        payload: payload
    }
}

export const createNewJob = (payload, flag) => {
    return {
        type: flag ? UPDATE_JOB : CREATE_NEW_JOB,
        payload: payload
    }
}

export const debouncedCreateNewJob = debounce((dispatch, payload, flag) => {
    dispatch(createNewJob(payload, flag));
}, 300);

export const createNewJobSuccess = (payload, flag) => {
    return {
        type: flag ? UPDATE_JOB + `_SUCCESS` : CREATE_NEW_JOB + `_SUCCESS`,
        payload: payload
    }
}

export const createNewJobFailed = (payload, flag) => {
    return {
        type: flag ? UPDATE_JOB + `_FAILED` : CREATE_NEW_JOB + `_FAILED`,
        payload: payload
    }
}

export const searchKeys = (key) => {
    return {
        type: SEARCHKEY,
        key: key
    }
}

export const getJobs = (offset, loading) => {
    return {
        type: GET_JOBS,
        offset: offset,
        loading: loading
    }
}

export const unAssignJobColor = () => {
    return {
        type: `UN_ASSIGN_COLOR`
    }
}

export const debouncedGetJobs = debounce((dispatch, offset, loading) => {
    dispatch(getJobs(offset, loading));
}, 300);

export const getJobsSuccess = (payload) => {
    return {
        type: GET_JOBS + `_SUCCESS`,
        payload: payload
    }
}

export const getJobsFailed = (payload) => {
    return {
        type: GET_JOBS + `_FAILED`,
        payload: payload
    }
}

export const getPlanningJobs = (dayDuration, isLoading) => {
    return {
        type: GET_PLANNING_JOBS,
        dayDuration: dayDuration,
        isLoading: isLoading
    }
}

export const debouncedGetPlanningJobs = debounce((dispatch, dayDuration, isLoading) => {
    dispatch(getPlanningJobs(dayDuration, isLoading));
}, 300);

export const getPlanningJobsSuccess = (payload) => {
    return {
        type: GET_PLANNING_JOBS + `_SUCCESS`,
        payload: payload
    }
}

export const getPlanningJobsFailed = (payload) => {
    return {
        type: GET_PLANNING_JOBS + `_FAILED`,
        payload: payload
    }
}

export const scheduleJobs = (payload) => {
    return {
        type: SCHEDULE_PLANNING_JOBS,
        payload: payload
    }
}

export const debouncedScheduleJobs = debounce((dispatch, payload) => {
    dispatch(scheduleJobs(payload));
}, 300);

export const scheduleJobsSuccess = (payload) => {
    return {
        type: SCHEDULE_PLANNING_JOBS + `_SUCCESS`,
        payload: payload
    }
}

export const scheduleJobsFailed = (payload) => {
    return {
        type: SCHEDULE_PLANNING_JOBS + `_FAILED`,
        payload: payload
    }
}

export const resetOpenJob = () => {
    return {
        type: RESET_OPEN_JOB
    }
}

export const getChooseJob = (id, loading) => {
    return {
        type: GET_CHOOSE_JOB,
        id: id,
        loading: loading
    }
}

export const debouncedGetChooseJob = debounce((dispatch, id, loading) => {
    dispatch(getChooseJob(id, loading));
}, 300);

export const getChooseJobSuccess = (payload) => {
    return {
        type: GET_CHOOSE_JOB + `_SUCCESS`,
        payload: payload
    }
}

export const getChooseJobFailed = (payload) => {
    return {
        type: GET_CHOOSE_JOB + `_FAILED`,
        payload: payload
    }
}

export const deleteJob = (id) => {
    return {
        type: DEL_JOB,
        id: id
    }
}

export const debouncedDeleteJob = debounce((dispatch, id) => {
    dispatch(deleteJob(id));
}, 300);

export const delJobSuccess = (id) => {
    return {
        type: DEL_JOB + `_SUCCESS`,
        id: id
    }
}

export const delJobFailed = (id) => {
    return {
        type: DEL_JOB + `_FAILED`,
        id: id
    }
}

export const getCustomers = (query) => {
    return {
        type: GET_CUSTOMERS,
        payload: {
            typeId: 4,
            active: 1,
            query: query
        }
    }
}

export const debouncedGetCustomers = debounce((dispatch, query = '') => {
    dispatch(getCustomers(query));
}, 300);

export const getCustomersSuccess = (payload) => {
    return {
        type: GET_CUSTOMERS + `_SUCCESS`,
        payload: payload
    }
}

export const getCustomersFailed = () => {
    return {
        type: GET_CUSTOMERS + `_FAILED`
    }
}

export const createCustomer = (payload) => {
    return {
        type: CREATE_CUSTOMER,
        payload: payload
    }
}

export const debouncedCreateCustomer = debounce((dispatch, payload) => {
    dispatch(createCustomer(payload));
}, 300);

export const editCustomer = (payload, id) => {
    return {
        type: EDIT_CUSTOMER,
        payload: payload,
        id: id
    }
}

export const debouncedEditCustomer = debounce((dispatch, payload, id) => {
    dispatch(editCustomer(payload, id));
}, 300);

export const createCustomerSuccess = (payload) => {
    return {
        type: CREATE_CUSTOMER + `_SUCCESS`,
        payload: payload
    }
}

export const createCustomerFailed = (payload) => {
    return {
        type: CREATE_CUSTOMER + `_FAILED`,
        payload: payload
    }
}

export const editCustomerSuccess = (payload) => {
    return {
        type: EDIT_CUSTOMER + `_SUCCESS`,
        payload: payload
    }
}

export const editCustomerFailed = (payload) => {
    return {
        type: EDIT_CUSTOMER + `_FAILED`,
        payload: payload
    }
}

export const setModal = (payload) => {
    return {
        type: SET_MODAL,
        payload: payload
    }
}

export const getJobStatues = () => {
    return {
        type: GET_JOB_STATUSES
    }
}

export const getJobByOrg = () => {
    return {
        type: GET_JOB_BY_ORG
    }
}

export const debouncedGetJobByOrg = debounce((dispatch) => {
    dispatch(getJobByOrg());
}, 300);

export const getJobStatuesSuccess = (payload) => {
    return {
        type: GET_JOB_STATUSES + `_SUCCESS`,
        payload: payload
    }
}

export const getJobStatuesFailed = (payload) => {
    return {
        type: GET_JOB_STATUSES + `_FAILED`,
        payload: payload
    }
}

export const getAssignedUsers = () => {
    return {
        type: GET_ASSIGNED_USERS
    }
}

export const debouncedGetAssignedUsers = debounce((dispatch) => {
    dispatch(getAssignedUsers());
}, 300);

export const getAssignedUsersSuccess = (payload) => {
    return {
        type: GET_ASSIGNED_USERS + `_SUCCESS`,
        payload: payload
    }
}

export const getAssignedUsersFailed = (payload) => {
    return {
        type: GET_ASSIGNED_USERS + `_FAILED`,
        payload: payload
    }
}

export const getColorList = () => {
    return {
        type: GET_JOB_COLORS
    }
}

export const debouncedGetColorList = debounce((dispatch) => {
    dispatch(getColorList());
}, 300);

export const getColorListSuccess = (payload) => {
    return {
        type: GET_JOB_COLORS + `_SUCCESS`,
        payload: payload
    }
}

export const getColorListFailed = (payload) => {
    return {
        type: GET_JOB_COLORS + `_FAILED`,
        payload: payload
    }
}

export const getFilterList = () => {
    return {
        type: GET_JOB_FILTERS
    }
}

export const debouncedGetFilterList = debounce((dispatch) => {
    dispatch(getFilterList());
}, 300);

export const getFilterListSuccess = (payload) => {
    return {
        type: GET_JOB_FILTERS + `_SUCCESS`,
        payload: payload
    }
}

export const getFilterListFailed = (payload) => {
    return {
        type: GET_JOB_FILTERS + `_FAILED`,
        payload: payload
    }
}

export const getAllAssignableUsers = () => {
    return {
        type: GET_ASSIGNABLE_USERS
    }
}

export const debouncedGetAllAssignableUsers = debounce((dispatch) => {
    dispatch(getAllAssignableUsers());
}, 300);

export const getAllAssignableUsersSuccess = (payload) => {
    return {
        type: GET_ASSIGNABLE_USERS + `_SUCCESS`,
        payload: payload
    }
}

export const getAllAssignableUsersFailed = (payload) => {
    return {
        type: GET_ASSIGNABLE_USERS + `_FAILED`,
        payload: payload
    }
}

export const updateJobStatus = (jobId, statusId, itemOrders, columns) => {
    return {
        type: UPDATE_JOB_STATUS,
        payload: {
            jobId: jobId,
            statusId: statusId,
            itemOrders: itemOrders
        },
        columns
    }
}

export const debouncedUpdateJobStatus = debounce((dispatch, jobId, statusId, itemOrders, columns) => {
    dispatch(updateJobStatus(jobId, statusId, itemOrders, columns));
}, 300);

export const updateJobStatusSuccess = (data) => {
    return {
        type: UPDATE_JOB_STATUS + `_SUCCESS`,
        payload: data
    }
}

export const updateJobStatusFailed = (payload) => {
    return {
        type: UPDATE_JOB_STATUS + `_FAILED`,
        payload: payload
    }
}

export const uploadVehicleImgs = (data) => {
    return {
        type: UPLOAD_JOB_VEHICLE_IMG,
        payload: data
    }
}

export const debouncedUploadVehicleImgs = debounce((dispatch, data) => {
    dispatch(uploadVehicleImgs(data));
}, 300);

export const uploadVehicleImgsSuccess = (data) => {
    return {
        type: UPLOAD_JOB_VEHICLE_IMG + `_SUCCESS`,
        payload: data
    }
}

export const uploadVehicleImgsFailed = (data) => {
    return {
        type: UPLOAD_JOB_VEHICLE_IMG + `_FAILED`,
        payload: data
    }
}

export const getJobVehicleImgs = (id) => {
    return {
        type: GET_JOB_VEHICLE_IMG,
        id: id
    }
}

export const debouncedGetJobVehicleImgs = debounce((dispatch, id) => {
    dispatch(getJobVehicleImgs(id));
}, 300);

export const getJobVehicleImgsSuccess = (data) => {
    return {
        type: GET_JOB_VEHICLE_IMG + `_SUCCESS`,
        payload: data
    }
}

export const getJobVehicleImgsFailed = (data) => {
    return {
        type: GET_JOB_VEHICLE_IMG + `_FAILED`,
        payload: data
    }
}

export const initVehicle = () => {
    return {
        type: 'INTIAL_VEHICLE'
    }
}

export const initial = () => {
    return {
        type: INITIAL_STATES,
    }
}

export const showEmptyJobsModal = () => {
    return {
        type: SHOW_EMPTY_JOBS_MODAL
    }
}

export const hideEmptyJobsModal = () => {
    return {
        type: HIDE_EMPTY_JOBS_MODAL
    }
}

export const setIsNewJob = (payload) => {
    return {
        type: SET_IS_NEW_JOB,
        payload: payload
    }
}