import {
    GET_VEHICLE_MAKES,
    CREATE_NEW_JOB,
    GET_JOBS,
    GET_PLANNING_JOBS,
    GET_CHOOSE_JOB,
    UPDATE_JOB,
    INITIAL_STATES,
    GET_CUSTOMERS,
    CREATE_CUSTOMER,
    GET_JOB_STATUSES,
    GET_JOB_COLORS,
    UPLOAD_JOB_VEHICLE_IMG,
    GET_JOB_VEHICLE_IMG,
    GET_JOB_BY_ORG,
    GET_ASSIGNED_USERS,
    GET_ASSIGNABLE_USERS,
    GET_JOB_FILTERS,
    SEARCHKEY,
    SCHEDULE_PLANNING_JOBS,
    UPDATE_JOB_STATUS,
    RESET_OPEN_JOB,
    EDIT_CUSTOMER,
    SET_MODAL,
    HIDE_EMPTY_JOBS_MODAL,
    SHOW_EMPTY_JOBS_MODAL,
    SET_IS_NEW_JOB,
} from "./actionTypes";
import { getDaysOfWeek } from '../../helpers/getWeekDays';
import { notification } from 'antd';
import { MAX_WEEKS } from "../../helpers/globals";

const LAST_WEEK = -1;

const initialState = {
    vehicleMakes: {
        loading: false,
        errors: {},
        message: "",
        data: {}
    },
    jobs: {
        loading: false,
        errors: {},
        message: "",
        data: []
    },
    planningJobs: {
        loading: false,
        errors: {},
        message: "",
        data: {}
    },
    newJob: {
        loading: false,
        errors: {},
        message: "",
        data: {},
        isSuccess: null,
        isUpdating: false,
        isCreating:false
    },
    customers: {
        loading: false,
        errors: {},
        message: "",
        data: {},
        per_page: 0,
        total: 0,
        last_page: 0,
    },
    customer: {
        loading: false,
        errors: {},
        message: "",
        data: {},
        modal: false,
        isSuccess: null
    },
    jobStatuses: {
        loading: false,
        errors: {},
        message: "",
        data: {},
        dataCount: 0,
        responded: 0
    },
    colorList: {
        loading: false,
        errors: {},
        message: "",
        data: []
    },
    vehicleImgs: {
        loading: false,
        errors: {},
        message: "",
        data: []
    },
    assignableUsers: {
        loading: false,
        errors: {},
        message: "",
        data: {
            images: []
        }
    },
    filterOptions: {
        loading: false,
        errors: {},
        message: "",
        data: {
        }
    },
    assignedUsers: {
        loading: false,
        errors: {},
        message: "",
        data: []
    },
    emptyJobsModal: {
        isVisible: false,
    },
    isNewJob: null,

    globalJobData: [],
    filter: {},

    isAuthenticated: localStorage.getItem('h-access_token') ? true : false,
};

// User type
const SuperAdmin = 1;
const Primary = 2;
const Subordinate = 3;
const Customer = 4;


const JobNotify = (description) => {
    notification.open({
        message: 'Alert!',
        description: description,
        onClick: () => {
        },
    });
}

const getScheduledJobs = (days, jobs) => {
    Object.keys(jobs).map(key => {
        jobs[key].map(job => {
            job.open = 0
        })
    })

    let newDays = days.map((day, index) => {
        return {
            date: day,
            jobs: jobs[day] ? jobs[day] : []
        }
    })

    newDays = [
        ...newDays,
        {
            date: 'unscheduled',
            jobs: jobs[""] ? jobs[""] : []
        },
        {
            date: 'checkin',
            jobs: jobs["checkin"] ? jobs["checkin"] : []
        }
    ]

    return newDays
}

const payloadFilter = (state, globalData, data) => {
    let filter = globalData ? globalData.map(item => {
        const filteredJobs = item.jobs.filter(job => {
            const vehicle = job?.vehicle;
            const customer = job?.customer;
            const color = job?.color;
            const insuranceCompany = job?.insurance_company;
    
            const matchModel = !data?.model || vehicle?.model === data?.model;
            const matchMake = !data?.make || vehicle?.make?.name === data?.make;
            const matchYear = !data?.year || vehicle?.year === data?.year;
            const matchCustomer = !data?.customer || `${customer?.firstname} ${customer?.lastname}` === data?.customer;
            const matchInsurance = !data?.insurance_company || insuranceCompany === data?.insurance_company;
            const matchColors = !data?.colors || data?.colors.includes(color);
    
            const matchDots = (!data?.dots || data.dots.some(dot => {
                const dotIndex = dot.id - 1;
                return job.dots_bits[dotIndex] === '1';
            }));

            return matchModel && matchMake && matchYear && matchCustomer && matchInsurance && matchColors && matchDots;
        });
    
        return {
            ...item,
            jobs: data ? filteredJobs : globalData
        };
    }) : [];

    return filter;
}

const getUniqueInsuranceCompanies = (data) => {
    const allJobs = data.flatMap(person => person.jobs);
    const allInsuranceCompanies = allJobs.map(job => job.insurance_company);
    const uniqueInsuranceCompanies = [...new Set(allInsuranceCompanies)].sort((a, b) => a.localeCompare(b));
    return uniqueInsuranceCompanies;
};

const getUniqueCustomers = (data) => {
    const allJobs = data.flatMap(person => person.jobs);
    const allCustomers = allJobs
      .filter(job => job.customer)
      .map(job => `${job?.customer?.firstname} ${job?.customer?.lastname}`);
    const uniqueCustomers = [...new Set(allCustomers)].sort((a, b) => a.localeCompare(b));
    return uniqueCustomers;
};

const getUniqueYears = (data) => {
    const allJobs = data.flatMap(person => person.jobs);
    const allYears = allJobs
      .filter(job => job.vehicle)
      .map(job => job?.vehicle?.year);
    const uniqueYears = [...new Set(allYears)].sort((a, b) => a - b);
    return uniqueYears;
};

const getUniqueModels = (data) => {
    const allJobs = data.flatMap(person => person.jobs);
    const allModels = allJobs
      .filter(job => job.vehicle)
      .map(job => job?.vehicle?.model);
    const uniqueModels = [...new Set(allModels)].sort((a, b) => a.localeCompare(b));
    return uniqueModels;
};

const getUniqueMakes = (data) => {
    const allJobs = data.flatMap(person => person.jobs);
    const allMakes = allJobs
      .filter(job => job?.vehicle?.make)
      .map(job => job?.vehicle?.make?.name);
    const uniqueMakes = [...new Set(allMakes)].sort((a, b) => a.localeCompare(b));
    return uniqueMakes;
};

const JobReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VEHICLE_MAKES:
            state = {
                ...state,
                vehicleMakes: {
                    ...state.vehicleMakes,
                    loading: true
                }
            };
            break;
        case GET_VEHICLE_MAKES + `_SUCCESS`:
            let tmpVehicleMakes = action.payload.data
                .map(item => {
                    item.value = item.name
                    return item
                })
            state = {
                ...state,
                vehicleMakes: {
                    ...state.vehicleMakes,
                    data: tmpVehicleMakes,
                    loading: false
                }
            };
            break;
        case GET_VEHICLE_MAKES + `_FAILED`:
            state = {
                ...state,
                vehicleMakes: {
                    ...state.vehicleMakes,
                    errors: {
                        message: "Oops!"
                    },
                    loading: false
                }
            };
            break;
        case SEARCHKEY:
            state = {
                ...state,
                jobStatuses: {
                    ...initialState.jobStatuses,
                    loading: true
                }
            }
            break;
        case GET_JOBS:
            state = {
                ...state,
                jobs: {
                    ...state.jobs,
                    loading: action.loading
                }
            }
            break;
        case GET_JOBS + `_SUCCESS`:
            state = {
                ...state,
                jobs: {
                    ...state.jobs,
                    data: action.payload.data,
                    total: action.payload.total,
                    offset: action.payload.offset,
                    limit: action.payload.limit,
                    loading: false
                }
            }
            break;
        case GET_JOBS + `_FAILED`:
            state = {
                ...state,
                jobs: {
                    ...state.jobs,
                    error: action.payload,
                    loading: false
                }
            };
            break;
        case GET_JOB_FILTERS:
            state = {
                ...state,
                filterOptions: {
                    ...initialState.filterOptions,
                    loading: true
                }
            }
            break;
        case GET_JOB_FILTERS + `_SUCCESS`:
            state = {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    data: action.payload.data,
                    loading: false
                }
            }
            break;
        case GET_JOB_FILTERS + `_FAILED`:
            state = {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    error: action.payload,
                    loading: false
                }
            };
            break;
        case GET_PLANNING_JOBS:
            state = {
                ...state,
                planningJobs: action.isLoading ? {
                    data: { ...getScheduledJobs(getDaysOfWeek(LAST_WEEK, MAX_WEEKS), {}) },
                    loading: action.isLoading
                } : {
                    data: state.planningJobs.data, 
                    loading: action.isLoading
                }
            }
            break;
        case GET_PLANNING_JOBS + `_SUCCESS`:
            let days = getDaysOfWeek(LAST_WEEK, MAX_WEEKS)
            let schedules = getScheduledJobs(days, action.payload.jobs)

            state = {
                ...state,
                planningJobs: {
                    ...state.planningJobs,
                    data: { ...schedules },
                    loading: false
                }
            }
            break;
        case GET_PLANNING_JOBS + `_FAILED`:
            state = {
                ...state,
                jobs: {
                    ...state.jobs,
                    error: action.payload,
                    loading: false
                }
            };
            break;
        case RESET_OPEN_JOB:
            state = {
                ...state,
                newJob: {
                    loading: false,
                    errors: {},
                    message: "",
                    data: {}
                },
                vehicleImgs: {
                    loading: false,
                    errors: {},
                    message: "",
                    data: []
                },
            }
            break;
        case GET_CHOOSE_JOB:
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    loading: action?.loading
                }
            }
            break;
        case GET_CHOOSE_JOB + `_SUCCESS`:
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    data: action.payload,
                    loading: false
                }
            }
            break;
        case GET_CHOOSE_JOB + `_FAILED`:
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    errors: action.payload,
                    loading: false,
                    message: false
                }
            };
            JobNotify('Network temporarily unavailable. Please try again in a few minutes.')
            break;
        case SCHEDULE_PLANNING_JOBS + `_SUCCESS`:
        case SCHEDULE_PLANNING_JOBS + `_FAILED`:
            break;
        case CREATE_NEW_JOB:
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    loading: false,
                    isCreating: true,
                    errors: {},
                    message: "",
                    data: {}
                },
            };
            break;
        case CREATE_NEW_JOB + `_SUCCESS`:
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    loading: false,
                    isCreating: false,
                    errors: {},
                    message: "Job #" + action.payload.data.id + " created",
                },
            };
            JobNotify(state.newJob.message);
            break;
        case CREATE_NEW_JOB + `_FAILED`:
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    newJobMessage: false,
                    error: action.payload,
                    loading: false,
                    isCreating: false,
                }
            };
            JobNotify("Failed to create job. Please try again or contact an administrator. Error code 47");
            break;
        case UPDATE_JOB:
            console.log('action, log', action)
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    data: action.payload
                }
            };
            break;
        case UPDATE_JOB + `_SUCCESS`:
            
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    // data: action.payload.data,
                    loading: false,
                    isUpdating:false,
                    errors: {},
                    isSuccess: true,
                    message: "Updated successfully!",
                },
            };
            break;
        case UPDATE_JOB + `_FAILED`:
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    loading: false,
                    isUpdating: false,
                    message: "Failed to update job. Please try again or contact an administrator. Error code 48.",
                    isSuccess: false,
                    errors: action.payload,
                },
            };
            break;
        
        case `UN_ASSIGN_COLOR`:
            state = {
                ...state,
                newJob: {
                    ...state.newJob,
                    data: {
                        ...state.newJob.data,
                        color_id: null
                    }
                }
            }
            break;
        case GET_CUSTOMERS:
            state = {
                ...state,
                isAuthenticated: localStorage.getItem('h-access_token') ? true : false,
                customers: {
                    ...state.customers,
                    loading: true
                },
                customer: {
                    ...state.customer
                }
            };
            break;
        case GET_CUSTOMERS + `_SUCCESS`:
            let tmpCustomers = action.payload.data
                .map(item => {
                    item.value = item.firstname + " " + item.lastname
                    return item
                })
            state = {
                ...state,
                customers: {
                    ...state.customers,
                    loading: false,
                    data: tmpCustomers,
                    errors: {},
                    total: action.payload.total,
                    per_page: action.payload.per_page,
                    last_page: action.payload.last_page
                }
            };
            break;
        case GET_CUSTOMERS + `_FAILED`:
            state = {
                ...state,
                customers: {
                    ...state.customers,
                    loading: false,
                    data: {},
                    errors: action?.payload?.error
                }
            };
            break;
        case CREATE_CUSTOMER:
            state = {
                ...state,
                customer: {
                    ...state.customer,
                    loading: true,
                    errors: {},
                    message: false
                }
            };
            break;
        case CREATE_CUSTOMER + `_SUCCESS`:
            let newCustomer = {
                ...action.payload.data,
                value: action.payload.data.firstname + " " + action.payload.data.lastname
            };
            state = {
                ...state,
                customer: {
                    ...state.customer,
                    loading: false,
                    message: true,
                    errors: {},
                    data: action.payload.data,
                    modal: false,
                    isSuccess:true
                },
                customers: {
                    ...state.customers,
                    data: [...(Object.keys(state?.customers?.data)?.length > 0 ? state?.customers?.data : []), newCustomer]
                }
            };
            break;
        case CREATE_CUSTOMER + `_FAILED`:
            state = {
                ...state,
                customer: {
                    ...state.customer,
                    loading: false,
                    message: false,
                    errors: action?.payload?.response?.data?.message ?? 'Failed to update customer. Try again later.',
                    data: {},
                    modal: false,
                    isSuccess: false
                },
            };
            break;
        case EDIT_CUSTOMER:
            state = {
                ...state,
                customer: {
                    ...state.customer,
                    loading: true,
                    errors: {},
                    message: false
                },
            };
            break;
        case EDIT_CUSTOMER + `_SUCCESS`:
            const updatedCustomers = Object.keys(state.customers.data)?.length > 0 ? state.customers?.data?.map(item => item.id === action.payload?.data?.id ? action.payload.data : item) : {}
            state = {
                ...state,
                customer: {
                    ...state.customer,
                    loading: false,
                    message: true,
                    errors: {},
                    data: action.payload.data,
                    modal: false,
                    isSuccess: true
                },
                customers: {
                    ...state.customers,
                    data: updatedCustomers
                }
            };
            break;
        case EDIT_CUSTOMER + `_FAILED`:
            state = {
                ...state,
                customer: {
                    ...state.customer,
                    loading: false,
                    message: false,
                    errors: action?.payload?.response?.data?.message ?? 'Failed to update customer. Try again later.',
                    data: {},
                    modal: false,
                    isSuccess: false
                },
            };
            break;
        case SET_MODAL:
            state = {
                ...state,
                customer: {
                    ...state.customer,
                    loading: false,
                    errors: {},
                    message: false,
                    modal: action.payload,
                    isSuccess: null
                },
            };
            break;
        case GET_JOB_BY_ORG:
        case GET_JOB_STATUSES:
            state = {
                ...state,
                jobStatuses: {
                    ...state.jobStatuses,
                    loading: true,
                    errors: {},
                    message: false,
                    responded: 0
                }
            };
            break;
        case GET_JOB_STATUSES + `_SUCCESS`:
            let count = action.payload.reduce((accumulator, currentObj) => {
                return accumulator + currentObj.jobs.length
            }, 0)

            const uniqueCompanies = getUniqueInsuranceCompanies(action.payload);
            const uniqueCustomerNames = getUniqueCustomers(action.payload);
            const uniqueYears = getUniqueYears(action.payload);
            const uniqueMakes = getUniqueMakes(action.payload);
            const uniqueModels = getUniqueModels(action.payload);
            
            let filtersD = !state.filter ? {...action.payload} : payloadFilter(state, action?.payload, state.filter);

            state = {
                ...state,
                globalJobData: action.payload,
                jobStatuses: {
                    ...state.jobStatuses,
                    loading: false,
                    message: true,
                    errors: {},
                    data: filtersD,
                    dataCount: count,
                    responded: 1,
                    uniqueCompanies: uniqueCompanies,
                    uniqueCustomerNames: uniqueCustomerNames,
                    uniqueYears: uniqueYears,
                    uniqueMakes: uniqueMakes,
                    uniqueModels: uniqueModels
                }
            };
            break;
        case 'UPDATE_FILTER':
            state = {
                ...state,
                filter: { ...state.filter, ...action.payload }
            };
            break;
        case 'CLEAR_FILTER':
            state = {
                ...state,
                filter: {}
            }
            break;
        case 'GLOBAL_FILTER':
            console.log(action?.payload, 'action?.payload')
            let filteredData = payloadFilter(state, state.globalJobData, action?.payload);
            
            state = {
                ...state,
                filter: action.payload,
                jobStatuses: {
                    ...state.jobStatuses,
                    data: filteredData
                }
            }
            break;
        case GET_JOB_STATUSES + `_FAILED`:
            state = {
                ...state,
                jobStatuses: {
                    ...state.jobStatuses,
                    loading: false,
                    message: false,
                    errors: action.payload,
                    data: {},
                    responded: 0
                },
            };
            break;
        case GET_ASSIGNED_USERS:
            state = {
                ...state,
                assignedUsers: {
                    ...state.assignedUsers,
                    loading: true,
                    errors: {},
                    message: false
                }
            };
            break;
        case GET_ASSIGNED_USERS + `_SUCCESS`:
            state = {
                ...state,
                assignedUsers: {
                    ...state.assignedUsers,
                    loading: false,
                    message: true,
                    errors: {},
                    data: [
                        ...action.payload
                    ]
                }
            };
            break;
        case GET_ASSIGNED_USERS + `_FAILED`:
            state = {
                ...state,
                assignedUsers: {
                    ...state.assignedUsers,
                    loading: false,
                    message: false,
                    errors: action.payload,
                    data: []
                },
            };
            break;
        case GET_JOB_COLORS:
            state = {
                ...state,
                colorList: {
                    ...state.colorList,
                    loading: true,
                    errors: {},
                    message: false
                }
            };
            break;

        case GET_JOB_COLORS + `_SUCCESS`:
            state = {
                ...state,
                colorList: {
                    ...state.colorList,
                    loading: false,
                    message: true,
                    errors: {},
                    data: action.payload
                }
            };
            break;
        case GET_JOB_COLORS + `_FAILED`:
            state = {
                ...state,
                colorList: {
                    ...state.colorList,
                    loading: false,
                    message: false,
                    errors: action.payload,
                    data: {}
                },
            };
            break;
        case UPLOAD_JOB_VEHICLE_IMG:
            state = {
                ...state,
                vehicleImgs: {
                    ...state.vehicleImgs,
                    loading: true,
                    errors: {},
                    message: false
                }
            };
            break;

        case UPLOAD_JOB_VEHICLE_IMG + `_SUCCESS`:
            state = {
                ...state,
                vehicleImgs: {
                    ...state.vehicleImgs,
                    loading: false,
                    message: true,
                    errors: {},
                    data: action.payload
                }
            };
            break;
        case UPLOAD_JOB_VEHICLE_IMG + `_FAILED`:
            state = {
                ...state,
                vehicleImgs: {
                    ...state.vehicleImgs,
                    loading: false,
                    message: false,
                    errors: action.payload,
                    data: {}
                },
            };
            break;
        case GET_JOB_VEHICLE_IMG:
            state = {
                ...state,
                vehicleImgs: {
                    ...state.vehicleImgs,
                    loading: true,
                    errors: {},
                    message: false
                }
            };
            break;

        case GET_JOB_VEHICLE_IMG + `_SUCCESS`:
            state = {
                ...state,
                vehicleImgs: {
                    ...state.vehicleImgs,
                    loading: false,
                    message: true,
                    errors: {},
                    data: action.payload
                }
            };
            break;
        case GET_JOB_VEHICLE_IMG + `_FAILED`:
            state = {
                ...state,
                vehicleImgs: {
                    ...state.vehicleImgs,
                    loading: false,
                    message: false,
                    errors: action.payload,
                    data: {}
                },
            };
            break;
        case GET_ASSIGNABLE_USERS:
            state = {
                ...state,
                assignableUsers: {
                    ...state.assignableUsers,
                    loading: true,
                    errors: {},
                    message: false
                }
            };
            break;

        case GET_ASSIGNABLE_USERS + `_SUCCESS`:
            let tmpAssignableUsers = action.payload.map(item => {
                item.value = item.firstname + " " + item.lastname
                return item
            })

            state = {
                ...state,
                assignableUsers: {
                    ...state.assignableUsers,
                    loading: false,
                    message: true,
                    errors: {},
                    data: tmpAssignableUsers
                }
            };
            break;
        case GET_ASSIGNABLE_USERS + `_FAILED`:
            state = {
                ...state,
                assignableUsers: {
                    ...state.assignableUsers,
                    loading: false,
                    message: false,
                    errors: action.payload,
                    data: {}
                },
            };
            break;
        case INITIAL_STATES + `Customer`:
            state = {
                ...state,
                customer: {
                    ...initialState.customer
                }
            };
            break;
        case 'INTIAL_VEHICLE':
            state = {
                ...state,
                vehicleImgs: {
                    ...state.vehicleImgs,
                    loading: false,
                    errors: {},
                    message: false,
                    data: {
                        images: state.vehicleImgs.data.images
                    }
                }
            };
            break;

        case INITIAL_STATES:
            state = {
                ...initialState,
            }
            break;

        case UPDATE_JOB_STATUS + `_SUCCESS`:
            state = {
                ...state,
                jobStatuses: {
                    ...state.jobStatuses,
                    data: action.payload,
                    loading: false,
                    message: false,
                    errors: action.payload,
                },
            };
            break
        case UPDATE_JOB_STATUS + `_FAILED`:
            state = {
                ...state,
                jobStatuses: {
                    ...state.jobStatuses,
                    loading: false,
                    message: false,
                    errors: action.payload,
                },
            };
            JobNotify('Updating job status failed. Please try again in a few minutes.')
            break;
        case SHOW_EMPTY_JOBS_MODAL:
            state = {
                ...state,
                emptyJobsModal: {
                    isVisible: true
                }
            }
            break;
        case HIDE_EMPTY_JOBS_MODAL:
            state = {
                ...state,
                emptyJobsModal: {
                    isVisible: false
                }
            }
            break;
        case SET_IS_NEW_JOB:
            state = {
                ...state,
                isNewJob: action.payload,
                emptyJobsModal: {
                    isVisible: false
                }
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default JobReducer;