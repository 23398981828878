import Dashboard from "../assets/images/dashboard.svg";
import ProductionBoard from "../assets/images/production-board.svg";
import Job from "../assets/images/jobs_icon.svg";
import Clock from "../assets/images/clock_icon.svg";
import User from "../assets/images/user_icon.svg";
import Notification from "../assets/images/noti_icon.svg";
import Setting from "../assets/images/setting_icon.svg";
import Customers from "../assets/images/user_icon.svg"
import Customer from "../components/Customer";
import NewJobMenuLink from "../components/NewJobMenuLink";

export const menus = [
    // {
    //   "title": "Dashboard",
    //   "name": "dashboard",
    //   "is_heading": false,
    //   "is_active": false,
    //   "class_name": "",
    //   "is_icon_class": true,
    //   "link": "/",
    //   "icon": Dashboard
    // },
    {
      "title": "Production",
      "name": "production_board",
      "is_heading": false,
      "is_active": false,
      "class_name": "",
      "is_icon_class": true,
      "link": "/production",
      "icon": ProductionBoard
    },
    {
        "title": "Planning",
        "name": "planning",
        "is_heading": false,
        "is_active": false,
        "class_name": "",
        "is_icon_class": true,
        "icon": Clock,
        "link": "/planning",
        "count": 0,
        "count_color": "#1658ac",
        "children": []
    },
    {
      "title": "Jobs",
      "name": "jobs",
      "is_heading": false,
      "is_active": false,
      "class_name": "",
      "is_icon_class": true,
      "icon": Job,
      "children": [
        {
          "title": "Job list",
          "name": "job_list",
          "is_heading": false,
          "is_active": false,
          "class_name": "",
          "link": "/jobs",
          "is_icon_class": true,
        },
        {
          "title":<NewJobMenuLink>Add new</NewJobMenuLink>,
          "name": "add_job",
          "is_heading": false,
          "is_active": false,
          "class_name": "",
          "is_icon_class": true,
        }
      ]
    },
    // {
    //   "title": "Customers",
    //   "name": "customers",
    //   "is_heading": false,
    //   "is_active": false,
    //   "class_name": "",
    //   "is_icon_class": true,
    //   "icon": User,
    //   "children": [
    //     {
    //       "title": "Search Customer",
    //       "name": "search_customer",
    //       "is_heading": false,
    //       "is_active": false,
    //       "class_name": "",
    //       "is_icon_class": true,
    //     },
    //     {
    //       "title": "Add new",
    //       "name": "add_customer",
    //       "is_heading": false,
    //       "is_active": false,
    //       "class_name": "",
    //       "is_icon_class": true,
    //     }
    //   ]
  // },
  {
    "title": "Customers",
    "name": "customers",
    "is_heading": false,
    "is_active": false,
    "class_name": "",
    "is_icon_class": true,
    "icon": Customers,
    "children": [
      {
        "title": "Search Customer",
        "name": "search_customer",
        "is_heading": false,
        "is_active": false,
        "class_name": "",
        "link": "/customers",
        "is_icon_class": true,
      },
      {
        "title": <Customer>Add New</Customer> ,
        "name": "add_new",
        "is_heading": false,
        "is_active": false,
        "class_name": "",
        "is_icon_class": true,
      }
    ]
  },
    {
      "title": "Settings",
      "name": "settings",
      "is_heading": false,
      "is_active": false,
      "class_name": "",
      "is_icon_class": true,
      "link": "/profile",
      "icon": Setting,
      "children": [
        {
          "title": "Connections",
          "name": "connections",
          "is_heading": false,
          "is_active": false,
          "class_name": "",
          "link": "/connections",
          "is_icon_class": true,
        }
      ]
    }
  ]